<template>
    <section class="products-holder" :color="`${zamuPurple2}`" >  
            <v-layout justify-space-around justify-center>
                <v-flex xs11 sm11 offset-sm1 class="mt-10">
                         <v-card flat tile color="" width="92%" class="transparent main-content-products">
                            <v-card-title primary-title class="justify-center">
                                    <h3 v-if="!is_screen_small" class="zamu-heading transact-heading product-heading">Our Clients</h3><br />
                                   <h3 v-if="is_screen_small" class="zamu-heading transact-heading product-heading">Our <br />Clients</h3><br />

                                    <v-card tile flat :color="`${zamuGrey1}`" height="1" class="justify-center mt-5 transact-underline">
                                        <v-card-text class="transact-divider divider-white"></v-card-text>
                                        <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                                    </v-card>
                            </v-card-title>
                            <v-row align="center" justify="center" class="mt-3">
                                <v-col cols="12"  lg="2" md="2" sm="12" xs="12" class="" v-for="(client, index) in clients" :key="index">
                                    <v-card  flat color="white"  min-height="200"  align="center" justify="center" class="pa-3" >
                                        <a target="_blank" :href="client.link">
                                            <v-img :src="require(`../../../../assets/images/clients/${client.logo}`)"  
                                            :width="(is_screen_small) ? '50%' : `${client.width}`" 
                                            :class="[(client.sizer) ? 'sacco-images' : '', (client.margin) ? 'sacco-images-margin' : '']" 
                                            :key="index"></v-img>
                                        </a>

                                    </v-card>
                                </v-col>
                            </v-row>

                    </v-card>
            </v-flex>
        </v-layout>
    </section>
</template>
<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
name: 'Clients',
mixins: [colorMixin, coreMixin],
computed: {
    clients () {
            const data = [
                {
                    logo: 'Skyline Sacco.png',
                    width: '70%',
                    link: 'https://skylinesacco.com/'
                },
                {
                    logo: 'Kwetu Sacco.png',
                    width: '90%',
                    link: 'https://www.kwetusacco.com/'


                },
                {
                    logo: 'Nyati Sacco.jpg',
                    width: '90%',
                    link: 'https://www.nyatisacco.co.ke/'


                },
                {
                    logo: 'Kitui Sacco.jpg',
                    width: '100%',
                    margin: 'adjust',
                    link: 'https://www.kituiteacherssacco.com/'


                },
                {
                    logo: 'IG Sacco.png',
                    width: '100%',
                    margin: 'adjust',
                    link: 'https://igsaccoltd.co.ke/'



                }
              
               
            ];
            return data;

    },
}


}
</script>
<style scoped>
@media (min-width: 960px) {
    .col-md-2 {
    flex: 0 0 19.6666666667%;
    max-width: 19.6666666667%;
}
}
.v-responsive__sizer {
    padding-bottom: 100% !important;
}
</style>
                        